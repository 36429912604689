import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import LineFull from '../images/linefull.jpg';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import '../styles/app.scss';

const Campkickstart = ({ data }) => {
    const { wordpressPage: post } = data;

    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle="Kinder Kickstart" />
            <section className="smallestwdt memberships display_none">
                <div
                    className=""
                    dangerouslySetInnerHTML={{
                        __html: post.content,
                    }}
                />
            </section>
            <section className="page-section centersec nopaddbottsec" id='nd-boxed'>
                <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
                <div class="elfsight-app-620497c4-227f-4047-9e27-4672cc0bd258" data-elfsight-app-lazy></div>
            </section>
            <section className="page-section smallestwdt" id="bookparty">
                <div className="container">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_register,
                        }}
                    />
                    <div className="bookawrap">
                        <a
                            href="https://form.jotform.com/241273991726161"
                            className="wrtsbtn yellowbtn fullbtn"
                            target="_blank"
                        >
                            Learn More
                        </a>
                    </div>
                </div>
            </section>
            <section className="page-section notoppaddsec">
                <div className="container smallestwdt">
                    <h2
                        className={`bluetxt centertext`}
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_1_title,
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_1_desc,
                        }}
                    />
                    <img src={LineFull} alt="linefull" />
                </div>
                <div className="margin-padding_div"></div>
                <div className="container smallestwdt">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_2_title,
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_2_desc,
                        }}
                    />
                    <img src={LineFull} alt="linefull" />
                </div>
                <div className="margin-padding_div"></div>
                <div className="container smallestwdt">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_3_title,
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_3_desc,
                        }}
                    />
                    <img src={LineFull} alt="linefull" />
                </div>
                <div className="margin-padding_div"></div>
                <div className="container smallestwdt">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_4_title,
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_4_desc,
                        }}
                    />
                    <img src={LineFull} alt="linefull" />
                </div>
                <div className="margin-padding_div"></div>
                <div className="container smallestwdt">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_5_title,
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_sec_5_desc,
                        }}
                    />
                    <img src={LineFull} alt="linefull" />
                </div>
                <div className="margin-padding_div"></div>
                <div className="container smallestwdt">
                    <h2
                        className={`bluetxt centertext`}
                        dangerouslySetInnerHTML={{
                            __html: post.acf.camp_join_kick,
                        }}
                    />
                    <div class="elfsight-app-0abc0f16-3db2-4baa-997d-6493709d7094" data-elfsight-app-lazy></div>
                    <div className="margin-padding_div"></div>
                    <img src={LineFull} alt="linefull" />
                    <div className="margin-padding_div"></div>
                    <div class="elfsight-app-4324e17e-f932-4bbb-ab7e-f8a30c7d5d41" data-elfsight-app-lazy></div>
                </div>                
            </section>
    {/*      <div className="smallestwdt campschoolsout">
                    <div className="main-flex_camp bg-secondary">
                        <h2 className="mainh2-camp">ACTIVITIES & CLASSES</h2>
                        <div className="column-flex_camp">

                            <div> <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_s_1,
                                }}
                            /> <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.acf.camp_desc_1,
                                    }}
                                /></div>
                            <div> <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_s_2,
                                }}
                            /> <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.acf.camp_desc_2,
                                    }}
                                /></div>

                        </div>
                    </div>

                    <div className="main-flex_camp bg-primary">
                        <div className="column-flex_camp">

                            <div> <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_s_3,
                                }}
                            /> <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.acf.camp_desc_3,
                                    }}
                                /></div>
                            <div> <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_s_4,
                                }}
                            /> <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.acf.camp_desc_4,
                                    }}
                                /></div>

                        </div>
                    </div>
                    <div className="main-flex_camp bg-secondary">
                        <div className="column-flex_camp">

                            <div> <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_s_5,
                                }}
                            /> <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.acf.camp_desc_5,
                                    }}
                                /></div>
                            <div> <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_s_6,
                                }}
                            /> <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.acf.camp_desc_6,
                                    }}
                                /></div>

                        </div>
                    </div>
                    <div className="main-flex_camp bg-primary display_none">
                        <div className="column-flex_camp">

                            <div> <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_s_7,
                                }}
                            /> <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.acf.camp_desc_7,
                                    }}
                                /></div>
                            <div> <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_s_8,
                                }}
                            /> <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.acf.camp_desc_8,
                                    }}
                                /></div>

                        </div>
                    </div>
                    <div className="main-flex_camp bg-primary">
                        <div className="column-flex_camp">

                            <div> <h3
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.camp_s_9,
                                }}
                            /> <div
                                    className=""
                                    dangerouslySetInnerHTML={{
                                        __html: post.acf.camp_desc_9,
                                    }}
                                /></div>
                            <div></div>

                        </div>
                    </div>
                    <section className="whywelist page-section bg-secondary text-white" id="openpbasic">
                        <div class="container flexwrap whywebluelist">
                            <div class="section_memberships whylistrgt flexbox toup"><h2 className="yellowtxt">ADDITIONAL INFORMATION</h2>
                                <ul class="startxt normalfontlist">
                                    <li>For children ages 3 to 5 years. Children must be potty trained.</li>
                                    <li>Unique and differentiated themes each week for multi-week programs.</li>
                                    <li>Snacks and water are provided; lunch must be provided by parents. Kids must bring a labeled bottle and a labeled change of clothes.</li>
                                    <li>Give us a call at the gym if you feel your child needs one-to-one assistance.
                                    </li>
                                    <li>A digitally signed enrollment form is required for signup.
                                    </li>
                                    <li>We Rock the Spectrum is not a licensed daycare facility.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>  */}

            <Footer />
        </>
    );
};
export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            content
            slug
            acf {
               camp_register              
               camp_sec_1_title
               camp_sec_1_desc
               camp_sec_2_title
               camp_sec_2_desc
               camp_sec_3_title
               camp_sec_3_desc
               camp_sec_4_title
               camp_sec_4_desc
               camp_sec_5_title
               camp_sec_5_desc
               camp_join_kick
               camp_s_1
               camp_desc_1
               camp_s_2
               camp_desc_2
               camp_s_3
               camp_desc_3
               camp_s_4
               camp_desc_4
               camp_s_5
               camp_desc_5
               camp_s_6
               camp_desc_6
               camp_s_7
               camp_desc_7
               camp_s_8
               camp_desc_8
               camp_s_9
               camp_desc_9
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;

export default Campkickstart;
